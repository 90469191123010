'use client'

import {useEffect, useRef, useState} from 'react'
import {HgButtonLink} from '../HgButton'
import {createPortal} from 'react-dom'
import {type HgDemoCTAProps} from './types'

export default function HgDemoCTA({
  text,
  buttonLinkProps,
  containerIdStart,
  containerIdEnd,
}: HgDemoCTAProps) {
  const [isVisible, setIsVisible] = useState(false)
  const [containerStart, setContainerStart] = useState<Element | null>(null)
  const [containerEnd, setContainerEnd] = useState<Element | null>(null)

  const [root, setRoot] = useState<HTMLElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const containerStart = document.querySelector(
      `[data-demo-id="${containerIdStart}"]`
    )
    const containerEnd = document.getElementById(containerIdEnd)
    const root = document.getElementById('root')

    setContainerStart(containerStart)
    setContainerEnd(containerEnd)
    setRoot(root)
  }, [containerIdStart, containerIdEnd])

  useEffect(() => {
    if (!containerStart) return

    let currentWindowY = window.scrollY

    function getScrollDirection(scrollY: number) {
      const scrollDirection = scrollY > currentWindowY ? 'down' : 'up'
      currentWindowY = scrollY
      return scrollDirection
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        const scrollDirection = getScrollDirection(window.scrollY)
        if (
          entry.isIntersecting &&
          containerRef.current &&
          scrollDirection === 'down'
        ) {
          containerRef.current.removeAttribute('aria-hidden')
          setIsVisible(true)
        }
        if (scrollDirection === 'up' || !containerEnd) {
          setIsVisible(entry.isIntersecting)
        }
      },
      {threshold: [0.5, 0.75, 0.85]}
    )

    const observerEnd = new IntersectionObserver(
      ([entry]) => {
        const scrollDirection = getScrollDirection(window.scrollY)
        if (
          entry.isIntersecting &&
          containerRef.current &&
          scrollDirection === 'up'
        ) {
          containerRef.current.removeAttribute('aria-hidden')
          setIsVisible(true)
        }
        if (scrollDirection === 'down') {
          setIsVisible(entry.isIntersecting)
        }
      },
      {threshold: [0.5, 0.75, 0.85]}
    )

    observer.observe(containerStart)

    if (containerEnd) {
      observerEnd.observe(containerEnd)
    } else {
      observerEnd.observe(containerStart)
    }

    return () => {
      observer.disconnect()
      observerEnd.disconnect()
    }
  }, [containerStart, containerEnd])

  if (!root) return null

  return createPortal(
    <div
      className="absolute left-0 top-0 hidden h-screen w-full items-center justify-center opacity-100 transition-opacity duration-500 aria-hidden:opacity-0 md:flex"
      aria-hidden={!isVisible}
    >
      <div
        className="group fixed bottom-32 z-[9999] flex w-fit translate-y-[100%] items-center justify-center gap-s3 rounded-full bg-surface-elevated p-s2 pl-20 shadow-[0px_10px_16px_0px_rgba(28,28,35,0.02),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_0px_3px_0px_rgba(28,28,35,0.09)] aria-hidden:hidden data-[animate=false]:animate-popUpReverse data-[animate=true]:animate-popUp"
        data-animate={isVisible}
        ref={containerRef}
        onAnimationEnd={e => {
          // aria-hidden to hide the button in the DOM after the animation finishes
          if (!isVisible) {
            e.currentTarget.setAttribute('aria-hidden', 'true')
          }
        }}
      >
        <p className="text-text-default opacity-0 transition-opacity duration-1000 arcadia-ui-1 group-data-[animate=false]:animate-slideUpMoreAndFadeReverse group-data-[animate=true]:animate-slideUpMoreAndFade">
          {text}
        </p>
        <HgButtonLink
          {...buttonLinkProps}
          variant="primary"
          newTab
          contentType="label"
          iconProps={{iconType: 'arrow-up-right-angle', position: 'right'}}
          // The transform pop in animation is throwing off the canvas resizing for some reason... just doing this for now
          className="opacity-0 group-data-[animate=false]:animate-slideUpMoreAndFadeReverse group-data-[animate=true]:animate-slideUpMoreAndFade [&_canvas]:!h-full [&_canvas]:!w-full"
        />
      </div>
    </div>,
    root
  )
}
